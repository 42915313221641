/**
* Generated automatically at built-time (2024-08-26T12:11:00.408Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "udekokken-leveret-til-spanien",templateKey: "sites/103-e3e7b57d-d800-47fd-af11-8f6becbc4d7f"};